import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <footer id="footer">
        <div className="container1">
          <dl className="row">
            <p>SQUARE CO., INC.<br />Copyright, SQUARE CO., INC All rights reserved <br /> info@4square.co.kr<br />
              서울특별시 강남구 논현로 105길 87 <br />
              070 4647 4827</p>

          </dl>
        </div>
      </footer>
    </div>
  );
};

export default Footer;

import React from 'react';

const Brand1 = () => {
  return (
    <div>
      <section id="One" className="stylevelo">

        <div className="inner">
          <header className="align-center">
          <img src='http://rbtjr1117.mycafe24.com/wp-content/uploads/2024/02/005.png' alt="brand1" />
          <a className="brandsite" href="https://www.lifeborn.kr"><button className="brandname">브랜드<br />웹사이트<br /> →</button></a>
          </header>
        </div>
      </section>


      <section id="three" className="wrapper">
       
        <div className="inner">
          <header id="brand-lifeborn" className="align-center">
            <p>이유있는 선택 <span className="hide-on-mobile">a reasonable choice</span></p>
            <p>생활 본책</p>
          </header>
          <div className="row1" >
            <img src="../images/brand/lifeborn/1.jpg" alt="brand1" />
            <img src="../images/brand/lifeborn/2.jpg" alt="brand1" />
            <img src="../images/brand/lifeborn/3.jpg" alt="brand1" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Brand1;

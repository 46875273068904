import React from 'react';
import { Link } from 'react-router-dom';

const Brand = () => {
  return (
    <div>
      <section id="One" class="wrapper stylebrand">
				<div class="inner">
				<header class="align-center">
						
						<h2>BRAND</h2>
					</header>
				</div>
			</section>
      <section id="three" class="wrapper">
			<p class="brandsite_left">BRAND.</p>
			<div class="inner">
				<header class="align-center">
					<p class="special">우리는 고객 중심의 가치를 추구하며, 제품 및 서비스의 품질, 혁신, 지속 가능성, 사회적 책임, 그리고 다양성을 중시합니다. 
						<br/>우리는 모든 브랜드에서 이러한 가치를 실현하며 고객들에게 최상의 경험을 제공합니다. </p>
						{/* <!-- <h2 style="font-weight: 700;"></h2> --> */}
				</header>
				<div class="gallery1">

					{/* <!-- 벨로 --> */}
					<div class="image fit">
						
						<Link to='/brand1' className='More'>
						<img src="images/brand/006.png" alt="" class="테두리"/>
                </Link>
					</div>
						
						{/* <!-- 빙글 --> */}
						<div  class="image fit">
						<Link to='/brand3' className='More'>
							<img src="images/brand/003.png" alt="" class="테두리"/>
							</Link>

						</div>
					
						{/* <!-- 런머시 --> */}
						<Link to='/brand2' className='More'>
							<img src="images/brand/002.png" alt="" class="테두리" />
						</Link>
					
						
						
						{/* <!-- 에브리보틀 --> */}
						<div  class="image fit">
						<Link to='/brand4' className='More'>
							<img src="images/brand/004.png" alt="" class="테두리" />
							</Link>

						</div>
						
						{/* <!-- 생활본책 --> */}
						<div  class="image fit">
						<Link to='/brand1' className='More'>
							<img src="images/brand/001.png" alt="" class="테두리" />
							</Link>

						</div>

						{/* <!-- 스콜 --> */}
						<div  class="image fit">
						<Link to='/brand5' className='More'>
							<img src="images/brand/005.png" alt="" class="테두리" />
							</Link>

						</div>

				</div>
			</div>
		</section>
    </div>
  );
};

export default Brand;

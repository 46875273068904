import React from 'react';


const Brand4 = () => {
  return (
    <div>
      <section id="One" class="stylevelo">

        <div class="inner">
          <header class="align-center">
          <img src='http://rbtjr1117.mycafe24.com/wp-content/uploads/2024/03/every1080.png' alt="brand1" />
          <a class="brandsite" href="https://www.everybottle.co.kr"><button class="brandname">브랜드<br />웹사이트<br /> </button></a>
          </header>
        </div>
      </section>


      <section id="three" class="wrapper">
       
        <div class="inner">
          <header id="brand-everybottle" class="align-center">
            <p>내 손에 안에 작은 욕실 <span class="hide-on-mobile"> A small bathroom in my hand</span></p>
            <p>EVERYBOTTLE</p>
          </header>
          <div class="row1" >
            <img src="../images/brand/everybottle/1.jpg" alt="brand1" />
            <img src="../images/brand/everybottle/2.jpg" alt="brand1" />
            <img src="../images/brand/everybottle/3.jpg" alt="brand1" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Brand4;

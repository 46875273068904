// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

// import Footer from './Footer';
import Home from './page/Home';
import Brand from './page/Brand';
import Contact from './page/Contact';
import Brand1 from './page/brand/brand1';
import Brand2 from './page/brand/brand2';
import Brand3 from './page/brand/brand3';
import Brand4 from './page/brand/brand4';
import Brand5 from './page/brand/brand5';
import Brand6 from './page/brand/brand6';

function App() {
  return (
    <div className="App">
      
      <Router>
      
        <div>
        <header id="header">
					<h1 id="logo" ><a href="/" className='logo1'>SQUARE</a></h1>
					<nav id="nav">
						<ul>
							<li><a href="/">Home</a></li>
							<li>
								<a href="#four">Brand</a>
								<ul>
									<li><a href="left-sidebar.html">VELO</a></li>
							<li><a href="right-sidebar.html">RUNMECY</a></li>
							<li><a href="no-sidebar.html">BINGGEUL</a></li>
							<li><a href="left-sidebar.html">EVERYBOTTLE</a></li>
							<li><a href="./page/brand_1.html">생활본책</a></li>
							<li><a href="no-sidebar.html">SQUALL</a></li>
									<li>
										<a href="/">Submenu</a>
										<ul>
											<li><a href="/">Option 1</a></li>
											<li><a href="/">Option 2</a></li>
											<li><a href="/">Option 3</a></li>
											<li><a href="/">Option 4</a></li>
										</ul>
									</li>
								</ul>
							</li>
							<li><a href="/contact">Contact</a></li>
							
						</ul>
					</nav>
				</header>
          
         <Routes>
          <Route path="/" element={<Home />} />
            <Route path="/brand" element={<Brand />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/brand1" element={<Brand1 />} />
            <Route path="/brand2" element={<Brand2 />} />
            <Route path="/brand3" element={<Brand3 />} />
            <Route path="/brand4" element={<Brand4 />} />
            <Route path="/brand5" element={<Brand5 />} />
            <Route path="/brand6" element={<Brand6 />} />
          </Routes>
        </div>

      </Router>

      
    </div>
    
  );
}

export default App;

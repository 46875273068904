import React from 'react';


const Brand2 = () => {
  return (
    <div>
      <section id="One" class="stylevelo">

        <div class="inner">
          <header class="align-center">
          <img src='http://rbtjr1117.mycafe24.com/wp-content/uploads/2024/02/03.png' alt="brand1" />
          <a class="brandsite" href="https://www.runmecy.kr"><button class="brandname">브랜드<br />웹사이트<br /> </button></a>
          </header>
        </div>
      </section>


      <section id="three" class="wrapper">
       
        <div class="inner">
          <header id="brand-runmecy" class="align-center">
            <p>레이저각인기의 대중화를 선언하다 <br /><span class="hide-on-mobile">declare the popularization of laser engraving machine</span></p>
            <p>RUNMECY</p>
          </header>
          <div class="row1">
            <img src="../images/brand/introimg/006.gif" alt="brand1" />
            <img src="../images/brand/introimg/007.gif" alt="brand1" />
            <img src="../images/brand/introimg/008.gif" alt="brand1" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Brand2;

import React from 'react';

const Brand3 = () => {
  return (
    <div>
      <section id="One" class="stylevelo">

        <div class="inner">
          <header class="align-center">
          <img src='http://rbtjr1117.mycafe24.com/wp-content/uploads/2024/03/bing1080.png' alt="brand1" className='mbmargin'/>
          <a class="brandsite" href="https://www.binggeul.com"><button class="brandname">브랜드<br />웹사이트<br /> →</button></a>
          </header>
        </div>
      </section>


      <section id="three" class="wrapper">
       
        <div class="inner">
          <header id="brand-binggeul" class="align-center">
            <p>다시 동심으로 <span class="hide-on-mobile"> back to the childhood</span></p>
            <p>BING;GEUL</p>
          </header>
          <div class="row1" >
            <img src="../images/brand/binggeul/001.png" alt="brand1" />
            <img src="../images/brand/binggeul/002.png" alt="brand1" />
            <img src="../images/brand/binggeul/003.png" alt="brand1" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Brand3;

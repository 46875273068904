import React from 'react';

const Home = () => {

	const handleNextClick = (event, targetSection) => {
    event.preventDefault();

    const nextSection = document.getElementById(targetSection);
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

	return (
		<div>

			
			<section id="one" className="spotlight style1 bottom">
				<video className='pc1' autoPlay loop muted >
					<source  src="../images/videomain.mp4" type="video/mp4" />
					
				</video>
				<video className='mo1' autoPlay loop muted >
					<source  src="http://rbtjr1117.mycafe24.com/wp-content/uploads/2024/02/22.mp4" type="video/mp4" />
					
				</video>
						<a href="#two"  className="goto-next scrolly"  onClick={(e) => handleNextClick(e, 'two')}>Next</a>
			</section>


			{/* <section id="two" className="spotlight style2 right">
			<div class="video-container">
    <video className="velovideo" autoPlay loop muted>
        <source src="http://rbtjr1117.mycafe24.com/wp-content/uploads/2024/03/mainvodod.mp4" type="video/mp4" />
    </video>
</div>

				<div className="content">
					<header>
						<h2>VELO 이야기</h2>	
					</header>
					<p>여행은 예측 불가능한 즐거움이 가득합니다. VELO 캐리어는 바로 그 점에서 영감을 받아 탄생했습니다. 3 in 1 세로 확장 기능을 갖춘 VELO는 여행 중 다양한 상황에 맞춰 변화하며, 스타일과 기능을 동시에 제공합니다. 단순한 수하물이 아닌, 여행의 풍요로움을 더해주는 스타일리시한 동반자입니다.</p>
					<ul className="actions">
						<li><a href="https://veloindigo.com/product/velo-3%EB%8B%A8-%EC%BA%90%EB%A6%AC%EC%96%B4/10/category/28/display/1/" className="button">Learn More</a></li>
					</ul>
				</div>
				<a href="#three" className="goto-next scrolly" onClick={(e) => handleNextClick(e, 'three1')}>Next</a>
			</section>
			<section id="three1" className="spotlight style3 left">
				<div className='runmecy_full'>
					<img src="http://rbtjr1117.mycafe24.com/wp-content/uploads/2024/03/002.png" alt="" />
				</div>

				<div className="content">
					<header>
						<h2>RUNMECY 레이저 각인기</h2>
					</header>
					<p>RUNMECY는 DDT Inc., 산업용 레이저 각인기 시장의 선두주자의 대중적 브랜드입니다. 2016년 설립된 DDT Inc.는 레이저 기술을 기반으로 한 첨단 전자 제품을 연구, 개발, 설계, 생산하는 기업으로, 레이저 각인 장비와 기술 응용 분야에서 풍부한 경험과 진보된 기술로 인정받고 있습니다. 지속적인 연구와 개발을 통해 다양한 제품들이 국제 표준인증(CE, KC 등)을 획득하였으며, 대량 생산 체제를 갖추고 있습니다.</p>
					<ul className="actions">
						<li><a href="https://runmecy.kr/product/detail.html?product_no=11&cate_no=53&display_group=1" className="button">Learn More</a></li>
					</ul>
				</div>
				<a href="#binggeul_full" className="goto-next scrolly" onClick={(e) => handleNextClick(e, 'two1')}>Next</a>
			</section>
			<section id="two1" className="spotlight style4 right">
			<div id='binggeul_full' className='binggeul_full'>
					<img src="http://rbtjr1117.mycafe24.com/wp-content/uploads/2024/03/003.png" alt="" />
				</div>
				<div className="content">
					<header>
						<h2>BING;GEUL</h2>
					</header>
					<p>'빙글'은 뛰어노는 어린 시절의 기쁨을 모든 연령대에 되살려줍니다. 빙글을 통해, 우리는 다시 한번 어린 시절의 순수한 행복을 느끼며, 일상의 스트레스에서 잠시 벗어날 수 있습니다.</p>
					<ul className="actions">
						<li><a href="https://binggeul.com/product/binggeul-%EC%A0%84%EB%8F%99-%EB%AC%BC%EC%B4%9D-s-1000/20/category/45/display/1/" className="button">Learn More</a></li>
					</ul>
				</div>
				<a href="#every_full" className="goto-next scrolly" onClick={(e) => handleNextClick(e, 'three2')}>Next</a>
			</section> */}


{/* 브랜드소개 */}
			<section id="four" className="wrapper style1 special fade-up bottomback">
				<div className="container">
					<header className="major">
						<div className='brand-h1'>BRAND</div>
						<div className='brand-p1'>브랜드 소개</div>
					</header>
					<div className="box alt">
						<div className="row gtr-uniform">
							<section className="col-4 col-6-medium col-12-xsmall">
								<a href="/brand6"><img src="images/brand/006.png" alt="img" className='img_size velo-color' /></a>

							</section>
							<section className="col-4 col-6-medium col-12-xsmall">
								<a href="/brand2"><img src="images/brand/002.png" alt="img" className='img_size runmecy-color' /></a>

							</section>
							<section className="col-4 col-6-medium col-12-xsmall">
								<a href="/brand3"><img src="images/brand/003.png" alt="img" className='img_size binggeul-color' /></a>

							</section>
							
							<section className="col-4 col-6-medium col-12-xsmall">
								<a href="/brand1"><img src="images/brand/001.png" alt="img" className='img_size lifeborn-color' /></a>

							</section>
							<section className="col-4 col-6-medium col-12-xsmall">
								<a href="/brand5"><img src="images/brand/005.png" alt="img" className='img_size squall-color' /></a>

							</section>
							<section className="col-4 col-6-medium col-12-xsmall">
								<a href="/"><img src="" alt="" className='img_size everybottle-color' /></a>

							</section>
						</div>
					</div>

				</div>
			</section>


			<section id="five" className="wrapper style2 special fade">
				<div className="container">
					<header>
						<h2>info@4square.co.kr</h2>

					</header>

				</div>
			</section>

		</div>
	);
};

export default Home;

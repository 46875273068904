import React from 'react';


const Brand5 = () => {
  return (
    <div>
      <section id="One" class="stylevelo">

        <div class="inner">
          <header class="align-center">
          <img src='http://rbtjr1117.mycafe24.com/wp-content/uploads/2024/03/squall1080.png' alt="brand1" />
          <a class="brandsite" href="https://squall.kr/"><button class="brandname">브랜드<br />웹사이트<br /> </button></a>
          </header>
        </div>
      </section>


      <section id="three" class="wrapper">
        
        <div class="inner">
          <header id="brand-squall" class="align-center">
            <p>비가 기다려지는 이유 </p> 
            <p>the reason of waiting for rain</p>
            <p>SQUALL</p>
          </header>
          <div class="row1" >
            <img src="../images/brand/squall/001.png" alt="brand1" />
            <img src="../images/brand/squall/002.png" alt="brand2" />
            <img src="../images/brand/squall/003.png" alt="brand3" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Brand5;

import React, { useState, useEffect, useRef } from 'react';

const Contact = () => {
  const [formData, setFormData] = useState({
    inquiryType: '광고문의',
    name: '',
    message: '',
    email: '',
  });

  const nameRef = useRef(null);
  const messageRef = useRef(null);
  const emailRef = useRef(null);


  useEffect(() => {
    console.log('Form Data:', formData);
  }, [formData]);

  const handleChange = (e) => {
    const { name, message,email , value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      [message]: value,
      [email]:value
    }));
  };

  const handleSubmit = (e) => {
    const name = nameRef.current.value.trim();
    const message = messageRef.current.value.trim();
    const email = emailRef.current.value.trim();
  

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!name || !message || !email) {
      alert('이름, 메시지, 이메일을 모두 입력하세요.');
      e.preventDefault();
    } else if (!email.match(emailRegex)) {
      alert('올바른 이메일 주소를 입력하세요.');
      e.preventDefault();
    } else {
      window.alert('이메일이 발송되었습니다.', '타이틀');
    }
  };

  return (
    <div className='contact_body'>
      <section id="One" className="wrapper style3">
        <div className="inner">
          <div className="align-center">
            <p style={{ fontSize: '32px', color:'black' , marginTop: '56px' }}>제휴 & 문의</p>
            {/* <h2>Contact</h2> */}
          </div>
        </div>
      </section>

      {/* Main */}
      <div id="main" className="container1">
        {/* Elements */}
        <div className="row 200%">
          <div className="5u$ 12u$(medium)">
            <iframe
            title="지도"
              id="google_map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3165.1502466803527!2d127.02481187649701!3d37.50437437205477!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca3e2e35ff431%3A0xf26679b8339643c5!2z7ISc7Jq47Yq567OE7IucIOqwleuCqOq1rCDrhbztmITroZwxMDXquLggODc!5e0!3m2!1sko!2skr!4v1694066299090!5m2!1sko!2skr"
              width="550"
              height="450"

              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>

          <div className="5u$ 12u$(medium) ">
            {/* Form */}
            <h3 style={{ fontSize: '18px', color:'black' }}>문의하기</h3>
            <form
              className="gform pure-form pure-form-stacked"
              method="POST"
              data-email="rbtjr1117@nate.com"
              target="iframe1"
              action="https://script.google.com/macros/s/AKfycbzqrruFcq4cXss3cV_42Q2Dhj_IpTJfMLVm-3_RjIdi8XYJF48mYS8smJ8ib40KupEK/exec"
            >
              {/* change the form action to your script url */}
              <div className="form-elements">
                <fieldset className="pure-group">
                  <label htmlFor="inquiryType">Inquiry Type:</label>
                  <select id="inquiryType" name="inquiryType">
                    <option value="광고문의">광고 문의</option>
                    <option value="회사문의">제휴 문의</option>
                    <option value="회사문의">브랜드 문의</option>
                  </select>
                </fieldset>

                <fieldset className="pure-group">
                  <label htmlFor="name">Name: </label>
                  <input id="name" name="name" placeholder="성함"  />
                </fieldset>

                <fieldset className="pure-group">
                  <label htmlFor="message">Message: </label>
                  <textarea id="message" name="message" rows="10" placeholder="문의제목 및 내용을 입력하세요."></textarea>
                </fieldset>

                <fieldset className="pure-group">
                  <label htmlFor="email">
                    <em>Your</em> Email Address:
                  </label>
                  <input id="email" name="email" type="email" value={formData.email} onChange={handleChange} />
                </fieldset>

                <button className="button-success pure-button button-xlarge" id="submitButton" onClick={handleSubmit}>
                  <i className="fa fa-paper-plane"></i>&nbsp;Send
                </button>
              </div>

              {/* Customise the Thankyou Message People See when they submit the form: */}
              <div className="thankyou_message" style={{ display: 'none' }}>
                <h2>
                  <em>Thanks</em> for contacting us! We will get back to you soon!
                </h2>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
